import "styles/global.scss";
import "sweetalert2/src/sweetalert2.scss";

import { RouterProvider, createBrowserRouter } from "react-router-dom";

import Booking from "pages/club-fitting/booking";
import OGBooking from "pages/Booking";
import ContactUs from "pages/ContactUs";
import Coupon from "pages/Coupon";
import Credits from "pages/simulator-bay/Credits";
import EditProfile from "pages/EditProfile";
import EditSimulator from "pages/simulator-bay/Edit";
import FindStore from "pages/FindStore";
import Loading from "components/Loading";
import Member from "pages/Member";
import MemberSimulator from "pages/simulator-bay/Member";
import Pdpa from "pages/Pdpa";
import PdpaSimulator from "pages/simulator-bay/Pdpa";
import ProfessionalProfiles from "pages/simulator-bay/ProfessionalProfiles";
import Profile from "pages/simulator-bay/Profile";
import Ranking from "pages/Ranking";
import ReactDOM from "react-dom/client";
import Redemtion from "pages/Redemtion";
import Register from "pages/Register";
import ReserveSimulator from "pages/simulator-bay/ReserveSimulator";
import ReserveSimulatorTraining from "pages/simulator-bay/ReserveSimulatorTraining";
import Rewards from "pages/Rewards";
import Root from "pages/Root";
import RootClubFitting from "pages/club-fitting/index";
import RootSimulatorBay from "pages/simulator-bay/index";
import SimulatorBayRegister from "pages/simulator-bay/register";
import Term from "pages/Term";
import TermSimulator from "pages/simulator-bay/Term";
import VConsole from "vconsole";
const vConsole = new VConsole();

const router = createBrowserRouter([
  {
    path: "/club-fitting",
    element: <RootClubFitting />,
    children: [
      {
        path: "",
        element: <Loading color="error" />,
      },
      {
        path: "booking",
        element: <Booking />,
      },
    ],
  },
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "",
        element: <Loading />,
      },
      {
        path: "register",
        element: <Register />,
      },
      {
        path: "member",
        element: <Member />,
      },
      {
        path: "find-store",
        element: <FindStore />,
      },
      {
        path: "contact-us",
        element: <ContactUs />,
      },
      {
        path: "edit-profile",
        element: <EditProfile />,
      },
      {
        path: "term-and-condition",
        element: <Term />,
      },
      {
        path: "pdpa-policy",
        element: <Pdpa />,
      },
      {
        path: "reward",
        element: <Rewards />,
      },
      {
        path: "reward/:id",
        element: <Redemtion />,
      },
      {
        path: "coupon",
        element: <Coupon />,
      },
      {
        path: "ranking",
        element: <Ranking />,
      },
      {
        path: "booking",
        element: <OGBooking />,
      },
    ],
  },
  {
    path: "/simulator-bay",
    element: <RootSimulatorBay />,
    children: [
      {
        path: "",
        element: <Loading color="error" />,
      },
      {
        path: "register",
        element: <SimulatorBayRegister />,
      },
      {
        path: "member",
        element: <MemberSimulator />,
      },
      {
        path: "edit-profile",
        element: <EditSimulator />,
      },
      {
        path: "terms-and-conditions",
        element: <TermSimulator />,
      },
      {
        path: "pdpa-policy",
        element: <PdpaSimulator />,
      },
      {
        path: "reserve-simulator",
        element: <ReserveSimulator />,
      },
      {
        path: "reserve-simulator-training",
        element: <ReserveSimulatorTraining />,
      },
      {
        path: "credits",
        element: <Credits />,
      },
      {
        path: "professional-profiles",
        element: <ProfessionalProfiles />,
      },
      {
        path: "professional-profiles/:id",
        element: <Profile />,
      },
    ],
  },
]);

const App = () => {
  return <RouterProvider router={router} />;
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<App />);
